define("discourse/plugins/discourse-antivirus/discourse/discourse-antivirus/templates/connectors/admin-dashboard-top/clamav-unavailable-notice", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="alert alert-error">
    {{i18n "antivirus.clamav_unavailable"}}
  </div>
  */
  {
    "id": "93/H1clA",
    "block": "[[[10,0],[14,0,\"alert alert-error\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"antivirus.clamav_unavailable\"],null]],[1,\"\\n\"],[13]],[],false,[\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-antivirus/discourse/discourse-antivirus/templates/connectors/admin-dashboard-top/clamav-unavailable-notice.hbs",
    "isStrictMode": false
  });
});