define("discourse/plugins/discourse-antivirus/discourse/routes/admin-plugins-antivirus", ["exports", "@ember/routing/route", "discourse/lib/ajax"], function (_exports, _route, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    controllerName: "admin-plugins-antivirus",
    model() {
      return (0, _ajax.ajax)("/admin/plugins/antivirus");
    },
    setupController(controller, model) {
      controller.setProperties({
        model,
        background_scan_stats: model.background_scan_stats
      });
    }
  });
});